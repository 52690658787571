













import { transformDataURL } from "@/helpers/file-reader";
import MNotificationVue from "@/mixins/MNotification.vue";
import { fileServices } from "@/services-v2/file.service";
import Vue from "vue";
export default Vue.extend({
  name: "CImageViewer",
  mixins: [
    MNotificationVue,
  ],
  props: {
    fileName: {
      type: String,
      default: undefined,
    }
  },
  data() {
    return {
      imgUri: null as any
    };
  },
  computed: {
    baseUrl(): string {
      return fileServices.baseUrl();
    }
  },
  watch: {
    fileName: {
      immediate: true,
      handler: "onFileNameChanged"
    }
  },
  methods: {
    async onFileNameChanged(newVal: string): Promise<void> {
      try {
        if (!newVal) return;
        const blob = await fileServices.getFile(newVal);
        this.imgUri = await transformDataURL(blob);
      } catch (error) {
        this.showErrorMessage("notif_file_get_fail");
      }
    }
  },
});
