var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("figure", [
    _vm.fileName
      ? _c("img", {
          staticClass: "mw-100 h-auto rounded border",
          attrs: { src: _vm.imgUri, alt: _vm.fileName }
        })
      : _c(
          "span",
          [
            _c("a-icon", { attrs: { type: "file" } }),
            _vm._v(" " + _vm._s(_vm.$t("lbl_file_not_found")) + " ")
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }